import _ from "@lodash";
import {
	Button,
	ClickAwayListener,
	Grow,
	IconButton,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import parse from "html-react-parser";
import moment from 'moment';

function PetProfilesPartial({ pet, vets, locationService }) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const [currentLocation, setCurrentLocation] = useState("");

	useEffect(() => {
		if (pet.zipcode) {
			getPlaceAddressByZipCode();
		}

		if (vets && vets.length > 0) {
			populateVetDetails();
		}
	}, []);

	function populateVetDetails() {
		vets.forEach((vet) => {
			const detail = locationService.getDetails(
				{ placeId: vet.googlePlaceId },
				(placeDetails) => {
					if (placeDetails.photos) {
						const openingHrs = placeDetails.opening_hours?.weekday_text ?? [];
						const newOpeningHrs = openingHrs.map((hrs) => ({
							day: hrs.split(" ")[0],
							time: hrs.split(" ")[1],
						}));

						const url = placeDetails.photos[0].getUrl({ maxHeight: 300 });

						vet.imageUrl = url;

						vet.address1 = placeDetails.formatted_address;
						vet.phoneNumber = placeDetails.formatted_phone_number;
						vet.rating = +placeDetails.rating;
						vet.website = placeDetails.website;
						vet.reviews = placeDetails.reviews?.length ?? 0;

						vet.imageUrl = url || "assets/img/logo.png";
						if (
							placeDetails.types &&
							placeDetails.types.includes("veterinary_care")
						)
							vet.practiceType = "Veterinary Care";
					}
				}
			);
		});
	}

	function getPlaceAddressByZipCode() {
		try {
			let request = {
				query: pet.zipcode,
				fields: ["All"],
				type: ["veterinary_care"],
			};

			locationService.textSearch(request, (result: Array<any>) => {
				if (result.length > 0) {
					const first = result[0];
					const addr = first.formatted_address;
					const city = addr.split(",")[1];
					const state = addr.split(",")[2];
					setCurrentLocation(`${city}, ${state.slice(0,3)}`);
					const lat = first.geometry.location.lat();
					const lng = first.geometry.location.lng();
					const googleUrl = `http://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lng}`;
					pet.googleUrl = googleUrl;
					pet.address = `${city}, ${state.slice(0,3)}`;
				} else {
					pet.address = "";
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === "Escape") {
			setOpen(false);
		}
	}

	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus();
		}

		prevOpen.current = open;
	}, [open]);

	function getAgeString(age: number) {
		if (age === 1) {
			return `one month`;
		} else if (age < 12) {
			return `${age} months`;
		} else if (age === 12) {
			return `one year`;
		} else if (age > 12) {
			const year = parseInt((age / 12).toString());
			const month = parseInt((age % 12).toString());
			let retString = "";
			if (year > 0) {
				retString = `${year == 1 ? year + " year" : year + " years"}`;
			}
			if (year > 0 && month > 0) {
				retString += `<br/>`;
			}
			if (month > 0) {
				retString += `${month == 1 ? month + " month" : month + " months"}`;
			}

			return retString;
		}
		return "";
	}

	return (
		<>
			<div className="flex flex-col lg:flex-row px-[2em] lg:px-[5em] w-full">
				<div className="flex w-full lg:w-1/2">
					<img
						className="my-auto w-full h-471 lg:h-full rounded-0 rounded-t-32 md:rounded-0 md:rounded-l-32"
						src={pet.imageUrl || "assets/img/no-pet-image.png"}
						alt="logo"
					/>
				</div>
				<div className="flex w-full flex-col lg:w-1/2 bg-[#FFFBEA] py-28 px-[23px] lg:px-[46px] gap-10 lg:gap-20 rounded-0  rounded-b-32 md:rounded-0 lg:rounded-r-32">
					<div className="flex flex-row w-full px-12">
						<div className="flex w-full flex-col leading-24px">
							<Typography className="font-robotoslab font-400 text-12px sm:text-24px leading-16px sm:leading-32px">
								Dog name
							</Typography>
							<Typography className="font-quicksand font-700 text-16px sm:text-30px leading-20px sm:leading-36px">
								{pet.petName}
							</Typography>
						</div>
						<div className="flex w-full flex-row leading-24px items-center gap-9 relative top-9">
							{pet.missingDate && (
								<>
									<img
										className="flex h-[20px] sm:h-[36px] w-[20px] sm:w-[35px] relative"
										src="assets/img/marker.svg"
										alt="logo"
									/>

									<div className="flex flex-col">
										<Typography className="font-robotoslab font-400 text-12px sm:text-18px leading-14px sm:leading-20px">
											Last Seen in {pet.address?.replace("United States", "USA")}
										</Typography>
									</div>
								</>
							)}
							{!pet.missingDate && (
								<>
									<img
										className="flex h-[20px] sm:h-[36px] w-[20px] sm:w-[35px]"
										src="assets/img/marker.svg"
										alt="logo"
									/>

									<div className="flex flex-col">
										<Typography className="font-robotoslab font-400 text-[8px] sm:text-20px leading-10px sm:leading-28px">
											Home
										</Typography>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="flex flex-row w-full py-10 px-12">
						<div className="flex w-full flex-col leading-24px">
							<Typography className="font-robotoslab font-400 text-12px sm:text-24px leading-16px sm:leading-32px">
								Breed
							</Typography>
							<Typography className="font-quicksand font-700 text-16px sm:text-30px leading-20px sm:leading-36px">
								{pet.breed}
							</Typography>
						</div>
					</div>
					<div className="flex flex-row w-full gap-10 sm:gap-20 px-12">
						<div className="flex flex-col pr-10 sm:pr-20 bg-[#DBF3FF] rounded-16 h-[78px] sm:h-[150px]  w-[100px] sm:w-[200px] items-center justify-center">
							<Typography className="font-robotoslab text-center font-400 text-12px sm:text-24px leading-16px sm:leading-32px mb-12 sm:mb-24">
								Age
							</Typography>
							<Typography className="font-quicksand text-center font-700 text-12px sm:text-24px leading-16px sm:leading-32px">
								{parse(getAgeString(pet.age))}
							</Typography>
						</div>
						<div className="flex flex-col pr-10 sm:pr-20 bg-[#FFFDC8] rounded-16 h-[78px] sm:h-[150px]  w-[100px] sm:w-[200px] items-center justify-center">
							<Typography className="font-robotoslab text-center font-400 text-12px sm:text-24px leading-16px sm:leading-32px mb-12 sm:mb-24">
								Gender
							</Typography>
							<Typography className="font-quicksand text-center font-700 text-12px sm:text-24px leading-16px sm:leading-32px">
								{pet.sex}
							</Typography>
						</div>
						<div className="flex flex-col pr-10 sm:pr-20 bg-[#FFE3E3] rounded-16 h-[78px] sm:h-[150px]  w-[100px] sm:w-[200px] items-center justify-center">
							<Typography className="font-robotoslab text-center font-400 text-12px sm:text-24px leading-16px sm:leading-32px mb-12 sm:mb-24">
								Size
							</Typography>
							<Typography className="font-quicksand text-center font-700 text-12px sm:text-24px leading-16px sm:leading-32px">
								{pet.weight}kg
							</Typography>
						</div>
					</div>
					<div className="flex flex-row flex-wrap w-full gap-20">
						<hr className="border border-[#CABC84] w-full opacity-30" />
					</div>
					<div className="flex flex-row flex-wrap w-full gap-10 sm:gap-20 py-5 sm:py-10 px-8">
						{pet.missingDate && (
							<div className="flex flex-col justify-center pl-10">
								<Typography className="font-quicksand font-700 text-16px sm:text-[36px] leading-20px sm:leading-40px">
									Lost time
								</Typography>
								<Typography className="font-robotoslab font-400 text-12px sm:text-20px leading-16px  sm:leading-28px pt-8 sm:pt-16">
									{/* {pet.missingSince} */}
									{moment(pet.missingDate).format('ha dddd MMM Do, YYYY')}
								</Typography>
							</div>
						)}

						{pet.foundDate && (
							<div className="flex flex-col justify-center pl-10">
								<Typography className="font-quicksand font-700 text-16px sm:text-[36px] leading-20px sm:leading-40px">
									Found
								</Typography>
								<Typography className="font-robotoslab font-400 text-12px sm:text-20px leading-16px  sm:leading-28px pt-8 sm:pt-16">
									{pet.foundSince}
								</Typography>
							</div>
						)}

						{!pet.alertStatus && (
							<div className="flex flex-col justify-center pl-10">
								<Typography className="font-quicksand font-700 text-16px sm:text-[36px] leading-20px sm:leading-40px">
									Not lost
								</Typography>
								<Typography className="font-robotoslab font-400 text-12px sm:text-20px leading-16px sm:leading-28px pt-8 sm:pt-16">
									Safe at home
								</Typography>
							</div>
						)}
					</div>
					<div className="flex flex-row flex-wrap w-full">
						<hr className="border border-[#CABC84] w-full opacity-30" />
					</div>
					<div className="flex flex-row justify-between w-full gap-10 sm:gap-20 py-10">
						<div className="flex flex-col w-full justify-center pl-10">
							<Typography className="font-quicksand font-700 text-16px sm:text-[36px] leading-20px sm:leading-40px">
								Owners details
							</Typography>

							<div className="flex w-full flex-row justify-between leading-24px gap-9 relative mt-16 sm:mt-[40px]">
								<div className="flex flex-row">
									<motion.div className="flex">
										<img
											className="flex h-[51px] sm:h-[102px] w-[51px] sm:w-[102px] rounded-full bg-transparent"
											src={pet.userPhotoUrl || "assets/img/no-owner.png"}
											alt="user photo"
										/>
									</motion.div>
									<motion.div className="flex flex-col gap-5 sm:gap-10 justify-center ml-10">
										<Typography className="font-robotoslab font-600 sm:font-700 text-12px sm:text-20px leading-16px sm:leading-28px capitalize">
											{pet.firstName} {pet.lastName}
										</Typography>
										<Typography className="font-robotoslab font-600 sm:font-700 text-12px sm:text-20px leading-16px sm:leading-28px capitalize">
											{pet.state.abbreviation}, {pet.country}
										</Typography>
									</motion.div>
								</div>
								{pet.missingDate && pet.shareContactInfo && (
									<div className="flex flex-row">
										<motion.div className="flex flex-col items-center justify-center">
											<Typography
												component="div"
												className="font-quicksand font-700 text-16px sm:text-[20px] leading-40px sm:leading-28px"
											>
												Contact{" "}
												<Typography
													component="span"
													className="hidden md:inline-block font-quicksand font-700 text-16px sm:text-[20px] leading-20px sm:leading-28px"
												>
													Lost Pet Owner
												</Typography>
											</Typography>
											<motion.div className="flex w-full flex-row items-center">
												<Typography
													component="div"
													className="flex flex-row gap-10 justify-center"
												>
													<Link
														to="#"
														onClick={(e) => {
															window.location.href = `mailto:${pet.email}`;
															e.preventDefault();
														}}
													>
														<EmailIcon className="text-peeva-yellow" />
													</Link>
													<Link
														to="#"
														onClick={(e) => {
															window.location.href = `tel:${pet.phone}`;
															e.preventDefault();
														}}
													>
														<LocalPhoneIcon className="text-peeva-yellow" />
													</Link>
												</Typography>
												<Typography
													component="div"
													className="md:flex flex-row gap-10 justify-center hidden"
												>
													<Button
														ref={anchorRef}
														id="composition-button"
														aria-controls={
															open ? "composition-menu" : undefined
														}
														aria-expanded={open ? "true" : undefined}
														aria-haspopup="true"
														onClick={handleToggle}
													>
														<KeyboardArrowDownIcon />
													</Button>
													<Popper
														open={open}
														anchorEl={anchorRef.current}
														role={undefined}
														placement="bottom-start"
														transition
														disablePortal
													>
														{({ TransitionProps, placement }) => (
															<Grow
																{...TransitionProps}
																style={{
																	transformOrigin:
																		placement === "bottom-start"
																			? "left top"
																			: "left bottom",
																}}
															>
																<Paper>
																	<ClickAwayListener onClickAway={handleClose}>
																		<MenuList
																			autoFocusItem={open}
																			id="composition-menu"
																			aria-labelledby="composition-button"
																			onKeyDown={handleListKeyDown}
																		>
																			<MenuItem onClick={handleClose}>
																				<Link
																					to="#"
																					onClick={(e) => {
																						window.location.href = `tel:${pet.phone}`;
																						e.preventDefault();
																					}}
																				>
																					Owner's Number
																				</Link>
																			</MenuItem>
																			{vets.slice(0, 1).map((v) => {
																				return (
																					<MenuItem
																						key={v.id}
																						onClick={handleClose}
																					>
																						<Link
																							to="#"
																							onClick={(e) => {
																								window.location.href = `tel:${v.phoneNumber}`;
																								e.preventDefault();
																							}}
																						>
																							Vet's Number
																						</Link>
																					</MenuItem>
																				);
																			})}

																			{(pet.backupContacts || [])
																				.slice(0, 1)
																				.map((c) => {
																					return (
																						<MenuItem
																							key={c.id}
																							onClick={handleClose}
																						>
																							<Link
																								to="#"
																								onClick={(e) => {
																									window.location.href = `tel:${c.phoneNumber}`;
																									e.preventDefault();
																								}}
																							>
																								Guardian's Number
																							</Link>
																						</MenuItem>
																					);
																				})}
																		</MenuList>
																	</ClickAwayListener>
																</Paper>
															</Grow>
														)}
													</Popper>
												</Typography>
											</motion.div>
										</motion.div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PetProfilesPartial;
