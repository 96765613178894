import _ from "../../../../../@lodash/@lodash";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	Avatar,
	Box,
	FormControl,
	FormLabel,
	IconButton,
	IconButtonProps,
	InputBase,
	NativeSelect,
	Stack,
	Switch,
	SwitchProps,
	styled,
} from "@mui/material";
import {
	CalendarIcon,
	DatePicker,
	DateValidationError,
} from "@mui/x-date-pickers";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { useLayoutEffect, useState } from "react";
import { BreedsType } from "../../addpet/types/BreedType";
import FieldLabel from "../../editprofile/components/FieldLabel";
import utilityService from "@fuse/services/utilityService";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import CustomOutlinePrimaryButton from "@fuse/core/CustomOutlinePrimaryButton/CustomOutlinePrimaryButton";
import { useNavigate } from "react-router";
import React from "react";
import moment from "moment";
import { MotionConfig, motion } from "framer-motion";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"& .MuiAutocomplete-inputRoot": {
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ced4da",
		fontSize: 16,
		padding: "10px 26px 10px 12px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
		},
	},
}));

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const BootstrapSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#52BFF4",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#9DC1Fc" : "#9DC1Fc",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#52BFF4",
			border: "6px solid #52BFF4",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#EEE" : "#EEE",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const schema = yup.object().shape({
	name: yup
		.string()
		.required("Pet Name is required")
		.min(3, "The Pet name must be at least 3 characters"),
	imageUrl: yup.string().required("Image is required"),
	microchip1: yup
		.string()
		.optional(),
	speciesId: yup
		.string()
		.required("Pet Species is required")
		.min(1, "Pet Species is required"),

	petBreedList: yup
		.array()
		.required("Pet Breed(s) is required")
		.min(1, "Pet Breed(s) is required"),
	sex: yup.string().nonNullable().required("Pet Sex is required"),
	dob: yup.date().required("Date of Birth is required"),
	lastRabiesVaccine: yup.date().nullable().optional(),
	spayedNeutered: yup.boolean(),
	shareContactInfo: yup.boolean(),
	missingDate: yup.date().required("Missing date is required"),
	enterZipcode: yup.boolean().required(),
	zipCode: yup.string().when(["enterZipcode"], {
		is: (a: boolean) => !a,
		then: (_schema) => _schema.required("Zipcode is required."),
		otherwise: (_schema) => _schema.nullable(),
	}),
});

function AddPetAlertForm({
	pet,
	breeds,
	allSpecies,
	allBrands,
	onUpdate,
	loading,
}) {
	const navigate = useNavigate();
	const petBreedList = pet.petBreedList.map((m) => m.id);
	const chip = pet.chips?.length > 0 ? pet.chips[0].chipId : "";
	const species = allSpecies.find((f) => f.id === pet.speciesId);
	const [enterZipcode, setEnterZipCode] = useState(true);
	const [drag, setDrag] = useState(false);
	const [allBreeds, setBreeds] = useState(breeds);
	const [Apiloading, setApiLoading] = useState(false);
	const [dobError, setDobError] = useState<DateValidationError | null>(null);
	const [missingDateError, setMissingDateError] =
		useState<DateValidationError | null>(null);
	const [petImage, setPetImage] = useState<any>(pet.imageUrl);
	const [submitted, setSubmitted] = useState(false);

	var sexList = [
		{ id: '0', name: "Unknown" },
		{ id: '1', name: "Male" },
		{ id: '2', name: "Female" },
	];
	let zero: number = 0;

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			name: pet.name,
			microchip1: chip,
			speciesId: pet.speciesId || "",
			petBreedList: petBreedList || "",
			sex: pet.sex.toString(),
			dob: pet.dob ? moment(pet.dob).toDate() : null,
			lastRabiesVaccine: pet.lastRabiesVaccine
				? moment(pet.lastRabiesVaccine).toDate()
				: null,
			imageUrl: pet.imageUrl,
			spayedNeutered: pet.spayedNeutered ?? false,
			shareContactInfo: pet.shareContactInfo ?? false,
			missingDate: pet.missingDate,
			enterZipcode: true,
			zipCode: pet.zipCopde,
		},
		resolver: yupResolver(schema),
	});
	const { control, trigger, watch, reset, handleSubmit, formState, setValue } =
		methods;
	const { errors, isValid, dirtyFields } = formState;
	useLayoutEffect(() => {
		console.log(errors);
	}, [errors]);

	useLayoutEffect(() => {
		onSpeciesChange(pet.speciesId);
	}, []);

	const dragOver = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragEnter = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragLeave = (e) => {
		e.preventDefault();
		setDrag(false);
	};

	const fileDrop = (e, onChange) => {
		e.preventDefault();
		setDrag(false);
		const files = e.dataTransfer.files;
		if (files.length) {
			if (files.length > 1) {
				//TODO: Show Alert here
			}
			handleFile(files[0], onChange);
		}
	};

	const handleFile = (file, onChange) => {
		if (!file.type.startsWith("image/")) {
			alert("Only image files can be uploaded");
			return;
		}
		const reader: FileReader = new FileReader();
		reader.onload = () => {
			if (typeof reader.result === "string") {
				onChange(`data:${file.type};base64,${btoa(reader.result)}`);
			} else {
				// TODO: SHOW ERROR
			}
		};
		reader.onerror = () => {
			// TODO: SHOW ERROR
		};
		reader.readAsBinaryString(file);
	};

	function onSpeciesChange(speciesId) {
		setBreeds([]);
		setValue("petBreedList", []);

		setApiLoading(true);

		utilityService
			.getPetBreedsBySpeciesId(speciesId)
			.then((res: any) => {
				if (res.result) {
					setBreeds(res.result);
					setApiLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setApiLoading(false);
			});
	}

	function onSubmit(data: any) {
		onUpdate(data);
	}

	function gotoDashboard() {
		navigate("/client/add-alert");
	}

	return (
		<>
			{loading && <FuseSplashScreen />}

			<form
				name="registerForm"
				noValidate
				className="flex w-full flex-col justify-center overflow-y-scroll gap-[20px]"
				id="signupform-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex w-full gap-10 mt-40">
					<div className="flex sm:justify-start align-start justify-center">
						<motion.div className="flex flex-col">
							<Controller
								control={control}
								name="imageUrl"
								render={({ field: { onChange, value } }) => (
									<Box
										className="relative flex items-center justify-center w-[200px] h-[200px] rounded-xl overflow-hidden border-dashed border-[2px] border-black"
										onDragOver={dragOver}
										onDragEnter={dragEnter}
										onDragLeave={dragLeave}
										onDrop={(e) => fileDrop(e, onChange)}
									>
										<div className="absolute inset-0 flex items-center justify-center z-20 w-full h-full cursor-pointer p-[40px_20px]">
											<div>
												<label
													htmlFor="pet-image"
													className="flex cursor-pointer"
												>
													<input
														accept="image/*"
														className="hidden"
														id="pet-image"
														type="file"
														onChange={async (e) => {
															function readFileAsync() {
																return new Promise((resolve, reject) => {
																	const file = e?.target?.files?.[0];
																	if (!file) {
																		return;
																	}
																	const reader: FileReader = new FileReader();

																	reader.onload = () => {
																		if (typeof reader.result === "string") {
																			resolve(
																				`data:${file.type};base64,${btoa(
																					reader.result
																				)}`
																			);
																		} else {
																			reject(
																				new Error(
																					"File reading did not result in a string."
																				)
																			);
																		}
																	};
																	reader.onerror = reject;
																	reader.readAsBinaryString(file);
																});
															}
															const newImage = await readFileAsync();
															onChange(newImage);
															setPetImage(newImage);
														}}
													/>
													<FuseSvgIcon className="text-white opacity-10 text-opacity-0 w-full h-full">
														heroicons-outline:camera
													</FuseSvgIcon>
												</label>
											</div>
											<div className="absolute top-10 right-6 cursor-pointer">
												<CloseIcon
													className="text-32"
													onClick={() => {
														onChange("");
														setPetImage("");
													}}
												/>
											</div>
										</div>
										<Avatar
											sx={{
												backgroundColor: "background.default",
												color: "text.secondary",
												borderRadius: 0,
											}}
											className="object-cover w-full h-full text-center text-[12px] font-700 leading-[24px]"
											src={value}
											alt={yup?.Schema?.name}
										>
											Drag and drop or click to upload your profile photo
										</Avatar>
									</Box>
								)}
							/>
							{submitted && !petImage && (
								<div className="flex w-full MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled muiltr-1yo8va7-MuiFormHelperText-root">
									Pet image is required
								</div>
							)}
						</motion.div>
					</div>

					<div className="flex flex-col w-full">
						<div className="flex flex-wrap max-w-full">
							<div className="w-full md:w-1/2 px-8">
								<FieldLabel>Pet Name</FieldLabel>
								<Controller
									name="name"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
											sx={{ border: "solid 2px black" }}
											required
											autoFocus
											id="petName"
											variant="outlined"
											fullWidth
											error={!!errors.name}
											helperText={errors?.name?.message as string}
										/>
									)}
								/>
							</div>
							<div className="w-full md:w-1/2 px-8">
								<FieldLabel>Birth Date</FieldLabel>
								<Controller
									control={control}
									name="dob"
									render={({ field: { value, onChange } }) => (
										<DatePicker
											value={value ? new Date(value) : ""}
											onChange={onChange}
											className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
											sx={{ border: "solid 2px black" }}
											format="MM/dd/yyyy"
											maxDate={new Date()}
											onError={(newError) => setDobError(newError)}
											slotProps={{
												textField: {
													id: "dob",
													InputLabelProps: {
														shrink: true,
													},
													fullWidth: true,
													variant: "outlined",
													helperText:
														submitted &&
														dobError &&
														"Date of birth is required",
												},
												actionBar: {
													actions: ["clear", "today"],
												},
											}}
											slots={{
												openPickerIcon: CalendarIcon,
											}}
										/>
									)}
								/>
							</div>
						</div>
						<div className="flex flex-wrap max-w-full">
							<div className="w-full md:w-1/2 px-8">
								<FieldLabel>Microchip Number(s)</FieldLabel>
								<Controller
									name="microchip1"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
											required
											autoFocus
											id="chipId"
											variant="outlined"
											fullWidth
											disabled
											sx={{ border: "solid 2px black" }}
											error={!!errors?.microchip1}
											helperText={errors?.microchip1?.message as string}
										/>
									)}
								/>
							</div>
							<div className="w-full md:w-1/2 px-8">
								<FieldLabel>Species</FieldLabel>
								<Controller
									name="speciesId"
									control={control}
									render={({ field: { onChange, value } }) => (
										<BootstrapAutoComplete
											// onChange={onChange}
											onChange={(
												event: any,
												newValue: { id: string; name: string }
											) => {
												onChange(newValue?.id);
												if (newValue?.id) {
													onSpeciesChange(newValue?.id);
												}
											}}
											value={value || null}
											autoFocus
											options={allSpecies}
											className="mb-20"
											// groupBy={(option: any) => option?.country}
											getOptionLabel={(option: any) =>
												typeof option == "object"
													? option
														? option?.name
														: ""
													: allSpecies.find((c) => c?.id == option)?.name ?? ""
											}
											renderInput={(params: any) => {
												return (
													<TextField
														{...params}
														autoFocus
														placeholder="Please Select"
														id="speciesId"
														variant="standard"
														fullWidth
														InputProps={{
															...params.InputProps,
															disableUnderline: true,
														}}
														error={!!errors.speciesId}
														helperText={errors?.speciesId?.message as string}
													/>
												);
											}}
										/>
									)}
								/>
							</div>
						</div>
						<div className="flex flex-wrap max-w-full">
							<div className="w-full md:w-1/2 px-8">
								<FieldLabel>Breed(s) - Add up to 3</FieldLabel>
								<Controller
									name="petBreedList"
									control={control}
									render={({ field: { onChange, value } }) => (
										<BootstrapAutoComplete
											multiple
											freeSolo
											options={allBreeds}
											className="mb-20"
											getOptionLabel={(option: any) => option?.name || ""}
											onChange={(event, newValue: any) => {
												if (newValue.length <= 3) {
													onChange(newValue.map((item) => item?.id));
												}
											}}
											value={
												value
													? ((value as any[]).map((id) =>
															_.find(allBreeds, { id })
													  ) as BreedsType)
													: ([] as BreedsType)
											}
											renderInput={(params) => (
												<TextField
													{...params}
													placeholder="Select Breeds"
													variant="outlined"
													InputLabelProps={{
														shrink: true,
													}}
													error={!!errors.petBreedList}
													helperText={errors?.petBreedList?.message as string}
												/>
											)}
										/>
									)}
								/>
							</div>
							<div className="w-full md:w-1/2 px-8">
								<FieldLabel>Sex</FieldLabel>
								<Controller
									name="sex"
									control={control}
									defaultValue={zero}
									render={({ field: { onChange, value } }) => (
										<BootstrapAutoComplete
											// onChange={onChange}
											onChange={(
												event: any,
												newValue: { id: string; name: string }
											) => {
												onChange(newValue?.id);
											}}
											value={value || null}
											autoFocus
											options={sexList}
											className="mb-20 w-full"
											// groupBy={(option: any) => option?.country}
											getOptionLabel={(option: any) =>
												isNaN(option)
													? option
														? option?.name
														: ""
													: sexList.find((c) => c?.id == option)?.name ?? ""
											}
											renderInput={(params: any) => {
												return (
													<TextField
														{...params}
														autoFocus
														placeholder="Please Select"
														id="sex"
														variant="standard"
														fullWidth
														InputProps={{
															...params.InputProps,
															disableUnderline: true,
														}}
														error={!!errors.sex}
														helperText={errors?.sex?.message as string}
													/>
												);
											}}
										/>
									)}
								/>
							</div>
						</div>
						<div className="flex flex-wrap max-w-full">
							<div className="w-full md:w-1/2 px-8">
								<FieldLabel>Date Reported Missing</FieldLabel>
								<Controller
									control={control}
									name="missingDate"
									render={({ field: { value, onChange } }) => (
										<DatePicker
											value={value ? new Date(value) : ""}
											onChange={onChange}
											className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
											sx={{ border: "solid 2px black" }}
											format="MM/dd/yyyy"
											maxDate={new Date()}
											onError={(newError) => setMissingDateError(newError)}
											slotProps={{
												textField: {
													id: "missingDate",
													InputLabelProps: {
														shrink: true,
													},
													fullWidth: true,
													variant: "outlined",
													helperText:
														submitted &&
														missingDateError &&
														"Missing Date is required",
												},
												actionBar: {
													actions: ["clear", "today"],
												},
											}}
											slots={{
												openPickerIcon: CalendarIcon,
											}}
										/>
									)}
								/>
							</div>
							<div className="flex sm:flex-row flex-col w-full md:gap-[20px]">
								<div className="flex w-full flex-col">
									<Controller
										name="spayedNeutered"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div className="flex justify-between items-center h-full w-full mb-20">
												<FieldLabel>Spayed/neutered</FieldLabel>
												<BootstrapSwitch
													checked={value}
													onChange={(ev) => {
														onChange(ev.target.checked);
													}}
													aria-label="spayed or neutered?"
												/>
											</div>
										)}
									/>
								</div>

								<div className="flex w-full flex-col">
									<Controller
										name="shareContactInfo"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div className="flex justify-between items-center h-full w-full mb-20">
												<FieldLabel>Share contact info</FieldLabel>
												<BootstrapSwitch
													checked={value}
													onChange={(ev) => {
														onChange(ev.target.checked);
													}}
													aria-label="share contact info"
												/>
											</div>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="flex flex-wrap max-w-full">
							<div className="flex items-center md:w-1/2 w-full relative px-8">
								<Controller
									name="enterZipcode"
									control={control}
									render={({ field: { onChange, value } }) => (
										<div className="flex justify-between items-center h-full w-full mb-20">
											<FieldLabel>
												Is your home ZIP Code where your pet was last seen?
											</FieldLabel>
											<BootstrapSwitch
												checked={value}
												onChange={(ev) => {
													onChange(ev.target.checked);
													setEnterZipCode(ev.target.checked);
												}}
												aria-label="enter zip code"
											/>
										</div>
									)}
								/>
							</div>
							{!enterZipcode && (
								<div className="flex flex-col items-center md:w-1/2 w-full relative px-8">
									<FieldLabel>Enter Zip Code Here</FieldLabel>
									<Controller
										name="zipCode"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 w-full bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												sx={{ border: "solid 2px black" }}
												required={!enterZipcode}
												autoFocus
												variant="outlined"
												fullWidth
												error={!!errors.zipCode}
												helperText={errors?.zipCode?.message as string}
											/>
										)}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
				<Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t justify-between">
					<Stack
						spacing={2}
						direction="row"
						className="flex w-full justify-between"
					>
						<CustomOutlinePrimaryButton
							type={"button"}
							onClick={(e) => {
								gotoDashboard();
							}}
						>
							Cancel
						</CustomOutlinePrimaryButton>
						<CustomPrimaryButton
							onClick={(e) => {
								console.log(control);
								setSubmitted(true);
								trigger();
							}}
						>
							Send
						</CustomPrimaryButton>
					</Stack>
				</Box>
			</form>
		</>
	);
}

export default AddPetAlertForm;
